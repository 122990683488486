import { branchesQuery } from '../../queries';
import { QueryHookResponse } from '../../types/internal';
import ServiceConfigs from '../../config';
import { Branch } from '../../types/generated';
import { useQuery } from '../../utils';

type UseBranchesType = ({
  pickupEnabled,
  dineIn,
  beachOrderEnabled,
  initialData,
}?: {
  pickupEnabled?: boolean;
  dineIn?: boolean;
  beachOrderEnabled?: boolean;
  initialData?: Array<Branch>;
}) => QueryHookResponse<Array<Branch>>;

const useBranches: UseBranchesType = ({ pickupEnabled, dineIn, beachOrderEnabled, initialData } = {}) =>
  useQuery<Array<Branch>>({
    query: branchesQuery,
    variables: { subdomain: ServiceConfigs.getSubDomain(), pickupEnabled, dineIn, beachOrderEnabled },
    handler: (res: any) => res.store.branches,
    config: { initialData },
  });

export default useBranches;
