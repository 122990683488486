import React from 'react';
import { Typography } from '@zydalabs/zac-react';

import { StyledTag } from 'common/styles';
import { StyledListItemWrapper, StyledDataWrapper, StyledTitle, DetailsSpan } from './style';

type ListItemType = React.FC<{
  dataTestId?: string;
  title: string;
  details: string;
  onClick: (item: any) => void;
  disabled?: boolean;
  icon?: React.ReactNode;
  tag?: string;
}>;

const ListItem: ListItemType = ({ dataTestId, title, details, onClick, disabled = false, icon, tag }) => (
  <StyledListItemWrapper onClick={onClick} disabled={disabled}>
    <StyledDataWrapper>
      {tag && <StyledTag>{tag}</StyledTag>}
      <Typography testId={dataTestId} variant="heading16" isDisabled={disabled}>
        <StyledTitle disabled={disabled}>{title}</StyledTitle>
      </Typography>
      <Typography variant="body16" isDisabled={disabled}>
        <DetailsSpan disabled={disabled}> {details}</DetailsSpan>
      </Typography>
    </StyledDataWrapper>
    <div>{icon && icon}</div>
  </StyledListItemWrapper>
);

export default ListItem;
