import React, { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';
import { Button } from '@zydalabs/zac-react';
import { StyledPageWrapper } from 'common/styles';
import { getAddressDetails, replaceRoute } from 'common/utils';
import { IcEdit, IcCheck } from 'common/icons';
import { usePersistedSelectedCoordinates, usePersistedUserAddressId } from 'common/hooks';
import ListItem from 'common/components/ListItem';
import { FULFILLMENT_MODES, URLS } from 'common/constants';

import { useBranches, useStoreSettings } from 'service/hooks';
import { DateTime } from 'luxon';
import { isBranchBusyAtDateTime } from 'modules/checkoutModule/utils/misc';
import { StyledLinkWrapper } from '../UserBookList/style';
import useHandleAddresses from '../../hooks/useHandleAddresses';
import ServiceConfigs from '../../../../service/config';

const List = dynamic(() => import('common/components/List'));

type AddressListProps = {
  showEditIcon?: boolean;
  showAddLink?: boolean;
  onBeforeSelectAddress?: () => void;
  onAfterSelectAddress?: (branchId: string) => void;
};

const AddressList: React.FC<AddressListProps> = ({
  showEditIcon = false,
  showAddLink = true,
  onAfterSelectAddress,
  onBeforeSelectAddress,
}) => {
  const router = useRouter();
  const { t } = useTranslation('address');
  const [persistedAddressID] = usePersistedUserAddressId();
  const [, setSelectedCoordinates, removePersistedCoordinates] = usePersistedSelectedCoordinates();
  const { addresses, isLoading, selectAddress, editAddress, addNewAddress } = useHandleAddresses({
    onAfterSelectAddress,
    onBeforeSelectAddress,
  });
  const { data: branches } = useBranches();
  const { data: storeSettings } = useStoreSettings();
  const isScheduleOrdersEnabled = storeSettings?.setting?.scheduleOrdersEnabled ?? false;

  const useDeliveryzonesMs = ServiceConfigs?.useDeliveryzonesMs;

  useEffect(() => {
    if (!useDeliveryzonesMs) removePersistedCoordinates();
  }, []);

  const handleOnClick = (address, isDeliveryBusy) => {
    if (showEditIcon) {
      setSelectedCoordinates({ lat: parseFloat(address.lat), lng: parseFloat(address.lng) });
      editAddress(address);
    } else if (address?.covered && (!isDeliveryBusy || isScheduleOrdersEnabled)) {
      if (useDeliveryzonesMs && (!address?.areaName || !address?.cityName)) {
        setSelectedCoordinates({ lat: parseFloat(address.lat), lng: parseFloat(address.lng) });
        replaceRoute({ pathname: URLS.EDIT_ADDRESS, query: { id: address?.id } });
      } else selectAddress(address);
    }
  };

  const renderItem = useCallback(
    address => {
      const assignedBranch = branches?.find(branch => branch.id === address.deliveryZone?.branchReferenceId.toString());
      const { busyMode, busyFrom, busyUntil } = assignedBranch || {};
      const isDeliveryBusy = isBranchBusyAtDateTime(DateTime.now(), FULFILLMENT_MODES.DELIVERY, busyMode, busyFrom, busyUntil);

      const shouldDisableAddress = !showEditIcon && (!address?.covered || (isDeliveryBusy && !isScheduleOrdersEnabled));

      let tag = null;
      if (isDeliveryBusy) {
        tag = isScheduleOrdersEnabled ? t('branches:busyOrderLater') : t('branches:busy');
      }

      return (
        <>
          <ListItem
            key={address.id}
            dataTestId={`${address.id}_address_title`}
            {...getAddressDetails(address, t, router.locale)}
            disabled={shouldDisableAddress}
            onClick={() => handleOnClick(address, isDeliveryBusy)}
            icon={(showEditIcon && <IcEdit />) || (address?.id === persistedAddressID ? <IcCheck /> : null)}
            tag={!showEditIcon && tag}
          />
        </>
      );
    },
    [router.locale, handleOnClick],
  );

  const isPrimaryButton = !addresses?.length;
  return (
    <StyledPageWrapper>
      {showAddLink && (
        <StyledLinkWrapper>
          <Button
            isFluid
            variant={isPrimaryButton ? 'primary' : 'tertiary'}
            text={t('addAddress')}
            testId="add_new_address_btn"
            onClick={addNewAddress}
          />
        </StyledLinkWrapper>
      )}
      <List data={(addresses as any) || []} renderItem={renderItem} isLoading={isLoading} />
    </StyledPageWrapper>
  );
};

export default AddressList;
